body > header {
    border-bottom-color: #8d6e5c;
    background-color: #634d40;
}

.header_p {
    background-color: #634d40;
}

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
    border-color: #634d40 !important;
    background-color: #634d40 !important;
}

.pagination > li > a,
.pagination > li > span {
    color: #634d40 !important;
}

.slogan {
    color: #fff;
}

.content {
    border-left: #8d6e5c;
    background-color: #fff;
}

h2, .h2 {
    border-bottom-color: #8d6e5c;
    color: #634d40;
}

.title_section a {
    color: #634d40;
}

.title_section a:hover {
    color: #8d6e5c;
}

nav#nav-principal {
    border-top-color: #c8b6ab;
    border-bottom-color: #c8b6ab;
    background-color: #fff;
}

nav#nav-principal ul li {
    border-bottom-color: #888;
}

nav#nav-principal ul > li:hover>a,
nav#nav-principal ul>li.actif>a {
    color: #634d40;
}

nav#nav-principal ul li a {
    color: #000;
}

nav#nav-principal > ul>li ul {
    border-top-color: #c8b6ab;
    background: #fff;
}

nav#nav-static-principal ul {
    background-color: #c8b6ab;
}

nav#nav-static-principal ul li a {
    color: #634d40;
}

nav#nav-static-principal ul li a.actif {
    color: #000;
}

.galerie {
    border-top-color: #c8b6ab;
    box-shadow: 0 1px 2px #888;
}

.mask {
    color: #fff;
    background-color: #634d40;
    background-color: rgba(200,182,171,0.8);
}

.galerie .addcommentaires {
    border-bottom-color: rgba(255,255,255,0.3);
}

.galerie .addcommentaires {
    color: #fff;
}

.mask > h3, .mask > .h3 {
    color: #fff;
}

.produits {
    border-top-color: #c8b6ab;
}

.produits .nomprod {
    border-bottom-color: #8d6e5c;
    color: #634d40;
}

.produits .desc {
    border-bottom-color: #8d6e5c;
    color: #000;
}

.produits .remise {
    color: #fff;
    background-color: #fc0;
}

.produits .oldprix {
    color: #8d6e5c;
}

.produits select {
    border-color: #8d6e5c;
}

.message_lo {
    border-left-color: #c8b6ab;
    box-shadow: 0 1px 2px #888;
}

.message_lo h4, .message_lo .h4 {
    color: #634d40;
}

.message_lo .web {
    color: #634d40;
}

.ie-inf-9 .message_lo {
    border-top-color: #bbb;
    border-right-color: #bbb;
    border-bottom-color: #bbb;
}

.message_lo:before {
    color: #eee;
}

.message_lo .note {
    color: #eee;
}

.form input[type=text],
.form textarea {
    border-color: #8d6e5c;
}

.lien {
    color: #000;
}

#footer {
    border-bottom: 1px solid #8d6e5c;
    color: #fff;
    background: none repeat scroll 0 0 #634d40;
}

#footer a {
    color: #fff;
}

#iview-preloader div {
    background: #000;
}

.iview-caption {
    text-shadow: #000 1px 1px 0;
    color: #fff;
}

.iview-caption a {
    color: #fff;
}

.iview-caption.caption2 {
    background: #00b4ff;
}

.iview-caption.blackcaption {
    background: #000;
    box-shadow: rgba(0,0,0,0.7) 10px 10px 15px 0;
}

#iview-preloader {
    border-color: #666;
}

#iview-preloader div {
    background: #666;
}

.button,
.produits .addbasket,
.addmsglo {
    border-color: #c8b6ab;
    color: #fff;
    background: none repeat scroll 0 0 #634d40;
}

.fil-dariane a {
    color: #634d40;
}

a {
    color: #634d40;
}

nav#nav-principal > ul ul {
    background: #fff;
}

.produit_etiquette:after {
    border-color: rgb(99,77,64) rgba(65,129,217,0) rgba(65,129,217,0);
}

.produit_etiquette {
    background: rgb(99,77,64);
}

.produit_etiquette2 {
    background: rgb(99,77,64);
}

.produit_etiquette2:after {
    border-color: rgba(0,0,0,0) rgb(68,50,40) rgba(0,0,0,0) rgba(0,0,0,0);
}

.pagination.pull-right li.active a {
    color: #fff !important;
}

/** Flux Panier **/

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: #634d40;
    border-color: #634d40;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: #634d40;
    color: #634d40;

    a, span {
        color: #634d40;
    }
}